body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-calendar {
  width: 580px;
  max-width: 100%;
  background: rgba(236, 229, 229, 0.13);
  border: 3px solid #75aaa6;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  outline: none;
  border: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  /*background: linear-gradient(45deg, #19aec2fa 30%, #6dffe7af 90%);*/
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 20px;
  border: 1px solid #218a6ea8;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #0d3b806b;
}
.react-calendar__navigation button[disabled] {
  background-color: #0d3b806b;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekdays__weekday:first-child {
  color: #d10000;
}

.react-calendar__month-view__weekdays__weekday:last-child {
  color: #41a3ff;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  justify-content: center;
  font-size: 0.5em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
button[disabled].react-calendar__tile {
  color: #000000;
}
button[disabled].react-calendar__tile.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
button[disabled].react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

button[disabled].react-calendar__tile.saturday {
  color: #41a3ff;
}

button[disabled].react-calendar__tile.holiday {
  color: #d10000;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  background: none;
}
.react-calendar__tile--active abbr {
  background: linear-gradient(45deg, #19aec2fa 40%, #6dffe7af 90%);
  color : white;

}

.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.filed1 {
  font-size: 0.5em;
  line-height: 1;
  background: #0c7489;
  color: #ffffff;
}
.filed2 {
  font-size: 0.5em;
  line-height: 1;
  background: #5dba91;
  color: #ffffff;
}
.filed3 {
  display: flexbox;
  font-size: 0.5em;
  line-height: 1;
  background: #88fad8;
  color: #ffffff;
}
.filed4 {
  display: flexbox;
  font-size: 0.5em;
  line-height: 1;
  background: #64a6bd;
  color: #ffffff;
}
.filed5 {
  display: flexbox;
  font-size: 0.5em;
  line-height: 1;
  background: #bbfd94;
  color: #4d2e2e;
}
.filed6 {
  display: flexbox;
  font-size: 0.5em;
  line-height: 1;
  background: #ffe260;
  color: #2e4939;
}
.filed7 {
  font-size: 0.5em;
  line-height: 1;
  background: #ffbd16;
  color: #ffffff;
}

.filed8 {
  font-size: 0.5em;
  line-height: 1;
  background: #ff9653;
  color: #ffffff;
}
.filed9 {
  font-size: 0.5em;
  line-height: 1;
  background: #f46464;
  color: #ffffff;
}
.filed10 {
  font-size: 0.5em;
  line-height: 1;
  background: #c44e54;
  color: #ffffff;
}
.filed0 {
  font-size: 0.5em;
  line-height: 1;
  color: #ffffff;
}
.item {
  width: 80px;
  max-width: 100%;
  padding: 0px;
  margin: 0;
}

p {
  border-bottom: 1px dashed #c0bec494;
  margin: 0;
  height: 12px;
}
p:first-child {
  border-top: 1px solid #74747594;
}

button.react-calendar__tile {
  align-items: center;
  display: flex;
  flex-direction: column;
  border: solid 0.02px rgba(55, 108, 117, 0.521);
  padding: 0;
}

.react-calendar__tile abbr {
  margin: 5px;
  padding: 5px 15px;
  font-size: 15px;
}

.calendar_div {
  height: calc(100% - 96px);
  width: calc(100% - 32px);
  padding: 16px;
  overflow: auto;
}
.main_div {
  height: 100vh;
  width: 100vw;
}
